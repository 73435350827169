<template>
  <div>
    <div class="container">
      <div class="flex flex-row items-center mb-6" style="margin-top: 80px;">
        <div class="flex flex-row items-center w-1/2">
          <div class="border-red-600 border-r-4 h-10 w-1 mr-2"></div>
          <div class="sm:text-lg md:text-2xl lg:text-3xl font-bold">
            ความรู้ด้านเทคโนโลยี : ศูนย์วิเคราะห์ข้อมูลดิจิทัล
          </div>
        </div>
      </div>

      <div class="cardContent mb-12">
        <div class="flex items-top justify-top">
          <img
            src="@/assets/mog/22_TH-TH6.svg"
            class="w-full h-full object-contain"
            alt=""
          />
        </div>
        <div class="content flex items-center h-20 p-5">
          ข้อมูลกลไกติดตามและวิเคราะห์สถานการณ์การ
          พัฒนาดิจิทัลเพื่อเศรษฐกิจและสังคมของประเทศ
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {};
</script>

<style scoped>
.container {
  font-family: KanitFont;
}

.cardContent {
  width: 364px;
  height: 325px;

  background: #ffffff;
  border: 1px solid #c4c4c4;
  box-sizing: border-box;
  border-radius: 0px;
}

.content {
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 24px;

  color: #3d3d3d;
}
</style>